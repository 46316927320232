.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--bg-color);
  padding: 1rem;
}

.not-found-content {
  max-width: 500px;
  text-align: center;
  padding: 3rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.not-found h1 {
  font-size: 5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.not-found h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.not-found p {
  margin-bottom: 2rem;
  color: var(--secondary-color);
}