
.link-create h1,
.link-edit h1,
.link-detail h1 {
  margin-bottom: 0;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.header-content {
  display: flex;
  flex-direction: column;
}

.link-url {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.url-label {
  margin-right: 0.5rem;
  font-weight: 500;
}

.link-url a {
  margin-right: 0.5rem;
  word-break: break-all;
}

.header-actions {
  display: flex;
  gap: 0.5rem;
}

.form-control {
  margin-bottom: 0.5rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.form-row > .form-group {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.col-md-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-md-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-md-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-md-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.form-actions {
  display: flex;
  gap: 0.5rem;
}

.link-info {
  margin-bottom: 2rem;
}

.detail-item {
  margin-bottom: 0.5rem;
}

.detail-label {
  font-weight: 500;
  margin-right: 0.5rem;
}

.detail-value {
  color: var(--dark-color);
  word-break: break-all;
}

.link-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.summary-item {
  text-align: center;
  margin-bottom: 1rem;
}

.summary-value {
  display: block;
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--primary-color);
}

.summary-label {
  font-size: 0.875rem;
  color: var(--secondary-color);
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.row > .col-md-4,
.row > .col-md-8 {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.stats-section h2,
.clicks-section h2 {
  margin-bottom: 1.5rem;
}

.charts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.chart-card {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.chart-card h3 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.chart {
  height: 300px;
  position: relative;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  gap: 0.5rem;
}

.pagination-info {
  margin: 0 1rem;
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .header-actions {
    margin-top: 1rem;
  }
  
  .row {
    flex-direction: column;
  }
  
  .col-md-4, .col-md-8, .col-md-5, .col-md-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .link-summary {
    margin-top: 1.5rem;
  }
  
  .charts-container {
    grid-template-columns: 1fr;
  }
}