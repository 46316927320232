.auth-form {
  width: 100%;
}

.auth-form h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--dark-color);
}

.auth-form .form-group {
  margin-bottom: 1.25rem;
}

.auth-form label {
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: block;
}

.auth-form .form-control {
  height: calc(2.5rem + 2px);
}

.auth-form .btn-block {
  width: 100%;
  padding: 0.75rem;
  font-weight: 500;
  margin-top: 1rem;
}

.auth-links {
  margin-top: 1.5rem;
  text-align: center;
}

.auth-links a {
  font-weight: 500;
}