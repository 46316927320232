:root {
    --primary-color: #4a6cf7;
    --primary-hover: #3a57e8;
    --secondary-color: #6c757d;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
    --info-color: #17a2b8;
    --light-color: #f8f9fa;
    --dark-color: #343a40;
    --white-color: #ffffff;
    --bg-color: #f5f8fa;
    --border-color: #dee2e6;
    --shadow-color: rgba(0, 0, 0, 0.1);
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    background-color: var(--bg-color);
    color: var(--dark-color);
    line-height: 1.5;
  }
  
  a {
    text-decoration: none;
    color: var(--primary-color);
  }
  
  a:hover {
    color: var(--primary-hover);
  }
  
  button {
    cursor: pointer;
  }
  
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .btn-primary {
    color: var(--white-color);
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  
  .btn-primary:hover {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
  }
  
  .btn-secondary {
    color: var(--white-color);
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
  }
  
  .btn-success {
    color: var(--white-color);
    background-color: var(--success-color);
    border-color: var(--success-color);
  }
  
  .btn-danger {
    color: var(--white-color);
    background-color: var(--danger-color);
    border-color: var(--danger-color);
  }
  
  .btn-outline-primary {
    color: var(--primary-color);
    background-color: transparent;
    border-color: var(--primary-color);
  }
  
  .btn-outline-primary:hover {
    color: var(--white-color);
    background-color: var(--primary-color);
  }
  
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--white-color);
    background-clip: border-box;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem var(--shadow-color);
    margin-bottom: 1.5rem;
  }
  
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid var(--border-color);
  }
  
  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }
  
  .card-title {
    margin-bottom: 0.75rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: var(--white-color);
    background-clip: padding-box;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .form-control:focus {
    color: #495057;
    background-color: var(--white-color);
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(74, 108, 247, 0.25);
  }
  
  .form-text {
    display: block;
    margin-top: 0.25rem;
    color: var(--secondary-color);
  }
  
  .text-danger {
    color: var(--danger-color) !important;
  }
  
  .text-success {
    color: var(--success-color) !important;
  }
  
  .text-center {
    text-align: center !important;
  }
  
  .mt-3 {
    margin-top: 1rem !important;
  }
  
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  
  .d-flex {
    display: flex !important;
  }
  
  .align-items-center {
    align-items: center !important;
  }
  
  .justify-content-between {
    justify-content: space-between !important;
  }
  
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid var(--border-color);
  }
  
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid var(--border-color);
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  
  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  
  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  
  .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
  
  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
  }
  
  .badge-primary {
    color: var(--white-color);
    background-color: var(--primary-color);
  }
  
  .badge-secondary {
    color: var(--white-color);
    background-color: var(--secondary-color);
  }
  
  .badge-success {
    color: var(--white-color);
    background-color: var(--success-color);
  }
  
  .badge-danger {
    color: var(--white-color);
    background-color: var(--danger-color);
  }
  
  .badge-warning {
    color: #212529;
    background-color: var(--warning-color);
  }
  
  .badge-info {
    color: var(--white-color);
    background-color: var(--info-color);
  }