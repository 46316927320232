.reports-list h1 {
  margin-bottom: 0;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.reports-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1.5rem;
}

.report-card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition: transform 0.2s, box-shadow 0.2s;
}

.report-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.report-card-header {
  padding: 1.25rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-card-header h2 {
  font-size: 1.25rem;
  margin: 0;
}

.report-card-body {
  padding: 1.25rem;
}

.report-type {
  margin-bottom: 1rem;
}

.type-label {
  font-weight: 500;
  margin-right: 0.5rem;
}

.type-value {
  color: var(--primary-color);
}

.report-dates {
  color: var(--secondary-color);
  font-size: 0.9rem;
}

.date-item {
  margin-bottom: 0.5rem;
}

.date-label {
  margin-right: 0.5rem;
}

.report-card-footer {
  padding: 1.25rem;
  border-top: 1px solid var(--border-color);
  text-align: center;
}

.report-detail h1 {
  margin-bottom: 0;
}

.report-info {
  margin-bottom: 2rem;
}

.detail-item {
  margin-bottom: 0.5rem;
}

.detail-label {
  font-weight: 500;
  margin-right: 0.5rem;
}

.detail-value {
  color: var(--dark-color);
}

.report-section {
  margin-bottom: 2.5rem;
}

.report-section h2 {
  margin-bottom: 1.5rem;
}

.summary-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 1.5rem;
}

.stat-item {
  text-align: center;
}

.stat-value {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  color: var(--primary-color);
}

.stat-label {
  font-size: 0.875rem;
  color: var(--secondary-color);
}

.charts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.chart-card {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.chart-card h3 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.chart {
  height: 300px;
  position: relative;
}

.form-actions {
  margin-top: 1.5rem;
  display: flex;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .page-header a {
    margin-top: 1rem;
  }
  
  .reports-grid {
    grid-template-columns: 1fr;
  }
  
  .summary-stats {
    flex-direction: column;
    gap: 1rem;
  }
  
  .stat-item {
    text-align: left;
  }
  
  .charts-container {
    grid-template-columns: 1fr;
  }
}