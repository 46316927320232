.project-detail h1 {
  margin-bottom: 0;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.header-actions {
  display: flex;
  gap: 0.5rem;
}

.project-info {
  margin-bottom: 2rem;
}

.project-details {
  margin-bottom: 1.5rem;
}

.detail-item {
  margin-bottom: 0.5rem;
}

.detail-label {
  font-weight: 500;
  margin-right: 0.5rem;
}

.detail-value {
  color: var(--dark-color);
}

.project-summary {
  display: flex;
  gap: 2rem;
}

.summary-item {
  text-align: center;
}

.summary-value {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  color: var(--primary-color);
}

.summary-label {
  font-size: 0.875rem;
  color: var(--secondary-color);
}

.section {
  margin-bottom: 2rem;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.section h2 {
  margin-bottom: 1.5rem;
}

.form-actions {
  display: flex;
  gap: 0.5rem;
}

.btn-group {
  display: flex;
  gap: 0.25rem;
}

.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.charts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.chart-card {
  flex: 1;
  min-width: 300px;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.chart-card h3 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.chart {
  height: 300px;
  position: relative;
}

@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .header-actions {
    margin-top: 1rem;
  }
  
  .project-summary {
    flex-direction: column;
    gap: 1rem;
  }
  
  .summary-item {
    text-align: left;
  }
  
  .charts-container {
    flex-direction: column;
  }
}