.navbar {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.navbar-logo a {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-user {
  position: relative;
  margin-left: 1rem;
}

.navbar-user span {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.navbar-user span:hover + .navbar-dropdown,
.navbar-dropdown:hover {
  display: block;
}

.navbar-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  min-width: 160px;
  display: none;
  z-index: 1000;
}

.navbar-dropdown a,
.navbar-dropdown button {
  display: block;
  padding: 0.5rem 1rem;
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  font-size: 0.9rem;
  color: var(--dark-color);
}

.navbar-dropdown a:hover,
.navbar-dropdown button:hover {
  background-color: #f8f9fa;
  color: var(--primary-color);
}