.sidebar {
  width: 250px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 60px;
  bottom: 0;
  z-index: 900;
  overflow-y: auto;
}

.sidebar-content {
  padding: 1.5rem 0;
}

.sidebar-header {
  padding: 0 1.5rem 1rem;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 1rem;
}

.sidebar-header h3 {
  font-size: 1.2rem;
  color: var(--dark-color);
  margin: 0;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu li {
  margin-bottom: 0.25rem;
}

.sidebar-menu a {
  display: block;
  padding: 0.75rem 1.5rem;
  color: var(--dark-color);
  font-size: 0.95rem;
  transition: all 0.2s;
  border-left: 3px solid transparent;
}

.sidebar-menu a:hover {
  background-color: rgba(74, 108, 247, 0.05);
  color: var(--primary-color);
}

.sidebar-menu a.active {
  background-color: rgba(74, 108, 247, 0.1);
  color: var(--primary-color);
  border-left-color: var(--primary-color);
}

.sidebar-divider {
  margin: 1rem 0;
  padding: 0 1.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: var(--secondary-color);
  font-weight: 700;
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .sidebar.show {
    transform: translateX(0);
  }
}