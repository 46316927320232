.auth-layout {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #4a6cf7 0%, #2842b1 100%);
  padding: 1rem;
}

.auth-container {
  width: 100%;
  max-width: 500px;
}

.auth-logo {
  text-align: center;
  margin-bottom: 2rem;
  color: white;
}

.auth-logo h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.auth-card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}