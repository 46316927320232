.profile h1 {
  margin-bottom: 0;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.profile-info {
  padding: 1rem 0;
}

.profile-item {
  margin-bottom: 1rem;
}

.profile-label {
  font-weight: 500;
  margin-right: 0.5rem;
}

.profile-value {
  color: var(--dark-color);
}

.form-actions {
  margin-top: 1.5rem;
  display: flex;
  gap: 0.5rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .page-header button {
    margin-top: 1rem;
  }
}