.dashboard h1 {
  margin-bottom: 1.5rem;
}

.stats-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.stat-card {
  flex: 1;
  min-width: 200px;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  text-align: center;
}

.stat-card-value {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.stat-card-label {
  color: var(--secondary-color);
  font-size: 1rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-bottom: 1.5rem;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  padding: 0 0.75rem;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding: 0 0.75rem;
}

.chart-container {
  position: relative;
  height: 300px;
  width: 100%;
}

.top-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.top-links li {
  margin-bottom: 0.75rem;
}

.top-links a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  transition: all 0.2s;
}

.top-links a:hover {
  background-color: rgba(74, 108, 247, 0.1);
}

.link-name {
  font-weight: 500;
  color: var(--dark-color);
}

.link-clicks {
  font-weight: 500;
  color: var(--primary-color);
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 992px) {
  .col-8, .col-4 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 1.5rem;
  }
}