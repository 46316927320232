.projects-list h1 {
  margin-bottom: 1.5rem;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1.5rem;
}

.project-card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition: transform 0.2s, box-shadow 0.2s;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.project-card-header {
  padding: 1.25rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-card-header h2 {
  font-size: 1.25rem;
  margin: 0;
}

.project-card-body {
  padding: 1.25rem;
}

.project-card-body p {
  margin-bottom: 1rem;
  color: var(--secondary-color);
}

.project-stats {
  display: flex;
  margin-top: 1rem;
}

.stat {
  flex: 1;
  text-align: center;
  padding: 0.5rem;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
}

.stat:last-child {
  margin-right: 0;
}

.stat-value {
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
}

.stat-label {
  font-size: 0.875rem;
  color: var(--secondary-color);
}

.project-card-footer {
  padding: 1.25rem;
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-date {
  font-size: 0.875rem;
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }
}